


const dataFaqs = [
    {
        id: 1,
        title: 'What are the NFTs?',
        text : 'Urna vitae erat et lacus, consectetur ac nulla vestibulum lobortis. Nulla dapibus urna volutpat venenatis, risus faucibus.',
        show: 'show'
    
    },
    {
        id: 2,
        title: 'How do i get NFTs?',
        text : 'Urna vitae erat et lacus, consectetur ac nulla vestibulum lobortis. Nulla dapibus urna volutpat venenatis, risus faucibus.'
    },
    {
        id: 3,
        title: 'How we can buy and invest NFT ?',
        text : 'Urna vitae erat et lacus, consectetur ac nulla vestibulum lobortis. Nulla dapibus urna volutpat venenatis, risus faucibus.'
    },
    {
        id: 4,
        title: 'Where we can buy and sell NFts ?',
        text : 'Urna vitae erat et lacus, consectetur ac nulla vestibulum lobortis. Nulla dapibus urna volutpat venenatis, risus faucibus.'
    },
    {
        id: 5,
        title: 'Who are the team behind the project?',
        text : 'Urna vitae erat et lacus, consectetur ac nulla vestibulum lobortis. Nulla dapibus urna volutpat venenatis, risus faucibus.'
    },
    {
        id: 6,
        title: 'What is your contract address ?',
        text : 'Urna vitae erat et lacus, consectetur ac nulla vestibulum lobortis. Nulla dapibus urna volutpat venenatis, risus faucibus.'
    },

   
]

export default dataFaqs;