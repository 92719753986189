import img1 from '../images/product/product9.png'
import img2 from '../images/product/product10.png'
import img3 from '../images/product/product11.png'
import img4 from '../images/product/product12.png'
import img5 from '../images/product/product13.png'
import img6 from '../images/product/product14.png'
import img7 from '../images/product/product15.png'
import img8 from '../images/product/product16.png'
import img9 from '../images/product/product17.png'


const dataRobot = [
    {
        id: 1,
        img: img1,
        title: '',
    },
    {
        id: 2,
        img: img2,
        title: '',
    },
    {
        id: 3,
        img: img3,
        title: '',
    },
    {
        id: 4,
        img: img4,
        title: '',
    },
    {
        id: 5,
        img: img5,
        title:'',
    },
    {
        id: 6,
        img: img6,
        title: '',
    },
    {
        id: 7,
        img: img7,
        title: '',
    },
    {
        id: 8,
        img: img8,
        title: '',
    },
    {
        id: 9,
        img: img9,
        title: '',
    },
   
]

export default dataRobot;