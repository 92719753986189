import img1 from '../images/product/product1.png'
import img2 from '../images/product/product2.png'
import img3 from '../images/product/product3.png'
import img4 from '../images/product/product4.png'
import img5 from '../images/product/product5.png'
import img6 from '../images/product/product6.png'
import img7 from '../images/product/product7.png'
import img8 from '../images/product/product8.png'
import img9 from '../images/product/product9.png'


const dataPlanet = [
    {
        id: 1,
        img: img1,
        title: '',
    },
    {
        id: 2,
        img: img2,
        title: '',
    },
    {
        id: 3,
        img: img3,
        title: '',
    },
    {
        id: 4,
        img: img4,
        title: '',
    },
    {
        id: 5,
        img: img5,
        title: '',
    },
    {
        id: 6,
        img: img6,
        title: '',
	},
	    {
        id: 7,
        img: img7,
        title: '',
	},
    {
        id: 8,
        img: img8,
        title: '',
	},
    {
        id: 9,
        img: img9,
        title: '',
	},
]

export default dataPlanet;