


import logo1 from '../images/partner/partner-1.png'
import logo2 from '../images/partner/partner-2.png'
import logo3 from '../images/partner/partner-3.png'
import logo4 from '../images/partner/partner-4.png'


const dataPartner = [
    {
        id: 1,
        img: logo1
    },
    {
        id: 2,
        img: logo2
    },
    {
        id: 3,
        img: logo3
    },

    {
        id: 4,
        img: logo4
    },
   
]

export default dataPartner;