const dataRoadmap = [
  {
    id: 1,
    title: 'Phase#1 - Q2 2023',
    title1: 'Project Planning & Social Media Engagement',
    class: 'left',
    list: [
      {
        id: 1,
        text: 'Finalizing NFT design & traits.',
      },
      {
        id: 2,
        text: 'Creating Twitter & Discord server.',
      },
      {
        id: 3,
        text: 'Website development & system testing.',
      },
    ],
  },
  {
    id: 2,
    title: 'PHASE#2 - Q2 2023',
    title1: 'Project Marketing & NFT Minting',
    class: 'right',
    list: [
      {
        id: 1,
        text: 'Project Marketing & NFT Minting.',
      },
      {
        id: 2,
        text: 'Submitting application on OnXRP & Xrp.cafe.',
      },
      {
        id: 3,
        text: 'Finalizing artwork and metadata.',
      },
      {
        id: 4,
        text: 'Launch our first infinite Voyage NFT collection.',
      },
    ],
  },
  {
    id: 3,
    title: 'PHASE#3 - Q3 2023',
    title1: 'Giveways and Royalties distribution',
    class: 'left',
    list: [
      {
        id: 1,
        text: 'Run giveaways on our discord server',
      },
      {
        id: 2,
        text: 'Sharing of royalties every month.',
      },
    ],
  },

  {
    id: 4,
    title: 'PHASE#4 - Q3 2023',
    title1: 'Partnerships & collabs',
    class: 'right',
    list: [
      {
        id: 1,
        text: 'Join projects on XRPL',
      },
      {
        id: 2,
        text: 'Continue supporting our NFT eco-system.',
      },
      {
        id: 3,
        text: 'Community voting.',
      },
    ],
  },
  {
    id: 5,
    title: 'PHASE#5 - Q4 2023',
    title1: 'New Collection Voting',
    class: 'left',
    list: [
      {
        id: 1,
        text: 'New collection NFT design selection.',
      },
      {
        id: 2,
        text: 'Community voting to finalize design.',
      },
      {
        id: 3,
        text: 'Free airdrop to your NFT holders.',
      },
    ],
  },
]

export default dataRoadmap
