const menus = [
  {
    id: 1,
    name: "Home",
    links: "#",
  },
  {
    id: 2,
    name: "About",
    links: "#About1",
  },
  {
    id: 3,
    name: "Road Map",
    links: "#RoadMap",
  },
  {
    id: 4,
    name: "Collection",
    links: "#Planet",
  },
  {
    id: 5,
    name: "Team",
    links: "#Team",
  },
];

export default menus;
