const dataAbout = [
  {
    id: 1,
    title: 'HIGH QUAILITY NFT',
    text:
      'Total Supply of 10000 beautiful NFTs divided into Planets, Robots & Spaceships.',
  },
  {
    id: 2,
    title: '100% Royalties',
    text:
      'Our NFT holders will be receive all royalties made on secondary market sales.',
  },
  {
    id: 3,
    title: 'NFT Matching',
    text: 'Collection all 3 categories and earn rewards.',
  },
  {
    id: 4,
    title: 'BIG COMMUNITY',
    text: 'Continuously provide benefits to NFT holders and build eco-system.',
  },
]

export default dataAbout
