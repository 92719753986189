import React , { useState ,useEffect } from 'react';
import './styles.scss';
import { Link } from 'react-router-dom';
import Button from '../button/Button';
import Button03 from '../button/Button03';




function Footer(props) {

    

    const [isVisible, setIsVisible] = useState(false);

    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    };
  
    useEffect(() => {
      const toggleVisibility = () => {
        if (window.pageYOffset > 500) {
          setIsVisible(true);
        } else {
          setIsVisible(false);
        }
      };
  
      window.addEventListener("scroll", toggleVisibility);
  
      return () => window.removeEventListener("scroll", toggleVisibility);
    }, []);

    return (

        <footer className="footer">
                <div className="action-box">
                    <div className="tf-container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="action-box-inner">
                                    <h2 className="title">JOIN OUR COMMUNITY</h2>
                                    <p className="content">We are bringing the best NFT collection on XRPL network </p>
                                    <div className="group-btn">
                                       
                                       <Button03 title='DISCORD' path='https://discord.gg/BzfCCsPM2S'/>

                                        <Button title="Twitter" path='https://twitter.com/infinitevoyage2' />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="bottom-inner">
                    <div className="tf-container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="bottom">
                                   
                                    <p className="copy-right">Infinite Voyage 2023 - ALL rights reserved</p>
    
                                    <ul className="social-item">
                                        <li><Link to="https://twitter.com/infinitevoyage2"><i className="fab fa-twitter"></i></Link></li>
                                        <li><Link to="https://m.facebook.com/infinitevoyage1/?wtsid=rdr_0YtXRMw3U92Kazg0u"><i className="fab fa-facebook"></i></Link></li>
                                        <li><Link to="https://www.instagram.com/infinitevoyage.space/"><i className="fab fa-instagram"></i></Link></li>
                                        <li><Link to="https://discord.gg/BzfCCsPM2S"><i className="icon-fl-vt"></i></Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {
                isVisible && 
                <Link onClick={scrollToTop}  to='#' id="scroll-top"></Link>
            }
           </footer>
    );
}

export default Footer;