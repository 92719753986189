import React from 'react';
import dataAbout from '../assets/fake-data/data-about';
import dataBanner from '../assets/fake-data/data-banner';
import dataPlanet from '../assets/fake-data/data-planet';
import dataRobot from '../assets/fake-data/data-robot';
import dataSpaceship from '../assets/fake-data/data-spaceship';


import dataRoadmap from '../assets/fake-data/data-roadmap';
import dataTeam from '../assets/fake-data/data-team';


import About1 from '../components/about/About1';
import Banner01 from '../components/banner/Banner01';

import Planet from '../components/collection/Planet';
import Robot from '../components/collection/Robot';
import Spaceship from '../components/collection/Spaceship';

import Footer from '../components/footer';

import RoadMap from '../components/roadmap/RoadMap2';
import Team from '../components/team/Team2';



function Home01(props) {

    return (
        <div className="home-1">
            <Banner01 data={dataBanner} />

            <About1 data={dataAbout}  />

			<Planet data={dataPlanet} />
			
			<Robot data={dataRobot} />

			<Spaceship data={dataSpaceship} />

            <RoadMap data={dataRoadmap} />

			<Team data={dataTeam} />
          
           <Footer />
           
        </div>

    );
}

export default Home01;