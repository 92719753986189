import img1 from '../images/product/product1.png'
import img2 from '../images/product/product2.png'
import img3 from '../images/product/product10.png'
import img4 from '../images/product/product5.png'
import img5 from '../images/product/product9.png'
import img6 from '../images/product/product8.png'
import img7 from '../images/product/product11.png'
import img8 from '../images/product/product7.png'
import img9 from '../images/product/product6.png'
import img10 from '../images/product/product8.png'
import img11 from '../images/product/product4.png'
import img12 from '../images/product/product3.png'
import img13 from '../images/product/product12.png'
import img14 from '../images/product/product13.png'
import img15 from '../images/product/product14.png'
import img16 from '../images/product/product15.png'
import img17 from '../images/product/product16.png'
import img18 from '../images/product/product17.png'
import img19 from '../images/product/product18.png'
import img20 from '../images/product/product19.png'
import img21 from '../images/product/product21.png'
import img22 from '../images/product/product22.png'
import img23 from '../images/product/product23.png'
import img24 from '../images/product/product24.png'
import img25 from '../images/product/product25.png'
import img26 from '../images/product/product20.png'


const dataCollection = [
    {
        id: 1,
        img: img1,
        title: 'sKELATON #01',
    },
    {
        id: 2,
        img: img2,
        title: 'sKISIRS #02',
    },
    {
        id: 3,
        img: img3,
        title: 'sKISIRS #02',
    },
    {
        id: 4,
        img: img4,
        title: 'sKISIRS #02',
    },
    {
        id: 5,
        img: img5,
        title: 'sKELATON #01',
    },
    {
        id: 6,
        img: img6,
        title: 'sKISIRS #02',
    },
    {
        id: 7,
        img: img7,
        title: 'sKELATON #01',
    },
    {
        id: 8,
        img: img8,
        title: 'sKISIRS#2',
    },
    {
        id: 9,
        img: img9,
        title: 'sKISIRS#2',
    },
    {
        id: 10,
        img: img10,
        title: 'SPOTIOR#2',
    },
    {
        id: 11,
        img: img11,
        title: 'sKISIRS#2',
    },
    {
        id: 12,
        img: img12,
        title: 'sKISIRS#2',
    },
    {
        id: 13,
        img: img13,
        title: 'sKISIRS#2',
    },
    {
        id: 14,
        img: img14,
        title: 'sKISIRS#2',
    },
    {
        id: 15,
        img: img15,
        title: 'sKISIRS#2',
    },
    {
        id: 16,
        img: img16,
        title: 'sKISIRS#2',
    },
    {
        id: 17,
        img: img17,
        title: 'sKISIRS#2',
    },
    {
        id: 18,
        img: img18,
        title: 'sKISIRS#2',
    },
    {
        id: 19,
        img: img19,
        title: 'sKISIRS#2',
    },
    {
        id: 20,
        img: img20,
        title: 'sKISIRS#2',
    },
    {
        id: 21,
        img: img21,
        title: 'sKISIRS#2',
    },
    {
        id: 22,
        img: img22,
        title: 'sKISIRS#2',
    },
    {
        id: 23,
        img: img23,
        title: 'sKISIRS#2',
    },
    {
        id: 24,
        img: img24,
        title: 'sKISIRS#2',
    },
    {
        id: 25,
        img: img25,
        title: 'sKISIRS#2',
    },
    {
        id: 26,
        img: img26,
        title: 'sKISIRS#2',
    },


   
]

export default dataCollection;