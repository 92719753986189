import img1 from '../images/product/product17.png'
import img2 from '../images/product/product18.png'
import img3 from '../images/product/product19.png'
import img4 from '../images/product/product20.png'
import img5 from '../images/product/product21.png'
import img6 from '../images/product/product22.png'
import img7 from '../images/product/product23.png'
import img8 from '../images/product/product24.png'
import img9 from '../images/product/product25.png'

const data  = [
    {
        id: 1,
        img: img1,
        title: ' ',
    },
    {
        id: 2,
        img: img2,
        title: ' ',
    },
    {
        id: 3,
        img: img3,
        title: ' ',
    },
    {
        id: 4,
        img: img4,
        title: ' ',
    },
    {
        id: 5,
        img: img5,
        title: ' ',
    },
    {
        id: 6,
        img: img6,
        title: ' ',
    },
    {
        id: 7,
        img: img7,
        title: ' ',
    },
    {
        id: 8,
        img: img8,
        title: ' ',
	},
	    {
        id: 9,
        img: img9,
        title: ' ',
    },
]

export default data ;