const dataBanner = [
  {
    id: 1,
    title: 'Exploring the unknown',
    desc: 'We are bringing best NFT collection XRPL network',
  },
  {
    id: 2,
    title: 'EXPLORE NFT COLLECTION',
    desc: 'We are the best way to check the rarity of NFT collection',
  },
]

export default dataBanner
