



import img1 from '../images/svg/work-1.svg'
import img2 from '../images/svg/work-2.svg'
import img3 from '../images/svg/work-3.svg'
import img4 from '../images/svg/work-4.svg'

const dataWork = [
    {
        id: 1,
        img: img1,
        title: 'How to mint using your Metamask wallet',
        text :'Users.',
    },
    {
        id: 2,
        img: img2,
        title: 'SELLECT THE QUAILITY',
        text :'Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat.',
    },

    {
        id: 3,
        img: img3,
        title: 'CONFIRM TRANSACTION',
        text :'Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat.',
    },

    {
        id: 4,
        img: img4,
        title: 'RECEIVE YOUR NFTS',
        text :'Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat.',
    },

]

export default dataWork;