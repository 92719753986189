


import img1 from '../images/blog/blog-1.jpg'
import img2 from '../images/blog/blog-2.jpg'
import img3 from '../images/blog/blog-3.jpg'
import img4 from '../images/blog/blog-4.jpg'
import img5 from '../images/blog/blog-5.jpg'
import img6 from '../images/blog/blog-6.jpg'
import img7 from '../images/blog/blog-7.jpg'
import img8 from '../images/blog/blog-8.jpg'
import img9 from '../images/blog/blog-9.jpg'

const dataBlog = [
    {
        id: 1,
        img: img1,
        title: 'SONIC LIVE',
        user: 'TONY NGUYEN',
        time: '20 JUN 2022',
        text :'One September 22, 2021 A volutpat nulla eros, nulla ultrices nec nulla imperdiet...'
    },
    {
        id: 2,
        img: img2,
        title: 'DIEGO',
        user: 'TONY NGUYEN',
        time: '20 JUN 2022',
        text :'One September 22, 2021 A volutpat nulla eros, nulla ultrices nec nulla imperdiet...'
    },
    {
        id: 3,
        img: img3,
        title: 'SONIC LIVE',
        user: 'TONY NGUYEN',
        time: '20 JUN 2022',
        text :'One September 22, 2021 A volutpat nulla eros, nulla ultrices nec nulla imperdiet...'
    },
    {
        id: 4,
        img: img4,
        title: 'CYBERPUNK',
        user: 'TONY NGUYEN',
        time: '20 JUN 2022',
        text :'One September 22, 2021 A volutpat nulla eros, nulla ultrices nec nulla imperdiet...'
    },
    {
        id: 5,
        img: img5,
        title: 'DIEGO',
        user: 'TONY NGUYEN',
        time: '20 JUN 2022',
        text :'One September 22, 2021 A volutpat nulla eros, nulla ultrices nec nulla imperdiet...'
    },
    {
        id: 6,
        img: img6,
        title: 'SONIC LIVE',
        user: 'TONY NGUYEN',
        time: '20 JUN 2022',
        text :'One September 22, 2021 A volutpat nulla eros, nulla ultrices nec nulla imperdiet...'
    },
    {
        id: 7,
        img: img7,
        title: 'SONIC LIVE',
        user: 'TONY NGUYEN',
        time: '20 JUN 2022',
        text :'One September 22, 2021 A volutpat nulla eros, nulla ultrices nec nulla imperdiet...'
    },
    {
        id: 8,
        img: img8,
        title: 'DIEGO',
        user: 'TONY NGUYEN',
        time: '20 JUN 2022',
        text :'One September 22, 2021 A volutpat nulla eros, nulla ultrices nec nulla imperdiet...'
    },
    {
        id: 9,
        img: img9,
        title: 'SONIC LIVE',
        user: 'TONY NGUYEN',
        time: '20 JUN 2022',
        text :'One September 22, 2021 A volutpat nulla eros, nulla ultrices nec nulla imperdiet...'
    },


   
]

export default dataBlog;