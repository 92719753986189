import img1 from '../images/team/team-1.jpg'
import img2 from '../images/team/team-2.jpg'
import img3 from '../images/team/team-3.jpg'
import img4 from '../images/team/team-4.jpg'
import img5 from '../images/team/team-5.jpg'
import img6 from '../images/team/team-6.jpg'
import img7 from '../images/team/team-7.jpg'

const dataTeam = [
  {
    id: 1,
    img: img1,
    name: 'Agent A',
    position: 'Smart Contract Dev',
    social: [
      {
        id: 1,
      },
      {
        id: 2,
      },
    ],
  },
  {
    id: 2,
    img: img2,
    name: 'Agent R',
    position: 'NFT Artist',
    social: [
      {
        id: 1,
      },
      {
        id: 2,
      },
    ],
  },
  {
    id: 3,
    img: img3,
    name: 'Agent F',
    position: 'Marketing Manager',
    social: [
      {
        id: 1,
      },
      {
        id: 2,
      },
    ],
  },

  {
    id: 4,
    img: img6,
    name: 'Agent R',
    position: 'Collab Manager',
    social: [
      {
        id: 1,
      },
      {
        id: 2,
      },
    ],
  },
]

export default dataTeam
