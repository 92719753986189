



import img1 from '../images/author/avt1.jpg'
import img2 from '../images/author/avt2.jpg'
import img3 from '../images/author/avt3.jpg'
import img4 from '../images/author/avt4.jpg'
import img5 from '../images/author/avt5.jpg'
import img6 from '../images/author/avt6.jpg'
const dataTestimonials = [

    {
        id: 1,
        text : 'Lorem ipsum dolor sit amet, adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.',
        name: 'Jenny Huynh',
        position: 'Marketer',
        avt: img1,
    },
    {
        id: 2,
        text : 'Lorem ipsum dolor sit amet, adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.',
        name: 'Jenny Huynh',
        position: 'Marketer',
        avt: img2,
    },
    {
        id: 3,
        text : 'Lorem ipsum dolor sit amet, adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.',
        name: 'Jenny Huynh',
        position: 'Marketer',
        avt: img3,
    },
    {
        id: 4,
        text : 'Lorem ipsum dolor sit amet, adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.',
        name: 'Jenny Huynh',
        position: 'Marketer',
        avt: img4,
    },
    {
        id: 5,
        text : 'Lorem ipsum dolor sit amet, adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.',
        name: 'Jenny Huynh',
        position: 'Marketer',
        avt: img5,
    },
    {
        id: 6,
        text : 'Lorem ipsum dolor sit amet, adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.',
        name: 'Jenny Huynh',
        position: 'Marketer',
        avt: img6,
    },

]

export default dataTestimonials;